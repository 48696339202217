<section class="bg-space-gray-100 auth-layout">
  <div class="container container-size-xs">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-md-8 col-lg-9 auth-layout__inner">
        <section class="px-2 px-md-0">
          <header class="text-max-width-xxs mb-5 ps-md-0">
            <h1 class="text-secondary">{{ title }}</h1>
            <p class="text-max-width-xxs type-body-sm auth-layout__header__text" *ngIf="text">{{ text }}</p>
          </header>

          <main class="bg-light auth-form ps-md-0 py-md-6 pe-md-5 auth-layout__main">
            <div class="text-max-width-xs">
              <ng-content select="[form]"></ng-content>
            </div>
          </main>

          <ng-content select="[footer]"></ng-content>
        </section>
      </div>
      <aside class="col-md-4 col-lg-3">
        <figure class="auth-layout__figure">
          <fstr-image
            [imageSizes]="[800, 1400, 1900]"
            [alt]="''"
            [image]="{ url: 'https://www.rova.nl/media/_DSF1398.jpg', anchor: 'middleright' }"
            [loading]="'eager'"
            [objectFitMode]="true"
            [decorative]="true"
          ></fstr-image>
        </figure>
      </aside>
    </div>
  </div>
</section>
